<template>
  <div>
    <vTitulo> Ajuda </vTitulo>
    <v-row class="justify-center align-center">
      <v-col cols="12" sm="8" class="order-sm-1 order-2">
        <div class="pa-4">
          <v-text-field
            placeholder="Pesquisar duvida"
            v-model="search"
            solo
            hide-details
            class="rounded searchInput d-block d-sm-inline-block"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="order-sm-2 order-1 text-center text-sm-right"
      >
        <div class="contemImagem mt-n16">
          <div class="pa-5 text-center text-sm-right mb-sm-0 mb-n5">
            <img src="@/assets/dash/help-image.svg" class="imagem mx-auto" />
          </div>
        </div>
      </v-col>
    </v-row>

    <div>
      <div
        v-for="(item, index) in renderHelpers"
        :key="index"
        class="cardFAQ"
        @click="openCloseChevron(index)"
      >
        <div class="headerFAQ">
          <div>
            <v-icon color="primary" v-show="!item.show"
              >mdi-chevron-down</v-icon
            >
            <v-icon color="primary" v-show="item.show">mdi-chevron-up</v-icon>
          </div>
          <div class="tituloFAQ ">
            {{ item.titulo }}
          </div>
        </div>
        <div v-show="item.show">
          <!-- <div
            v-if="item.isHTML"
            class="mensagemFAQ"
            v-html="item.mensagem"
          ></div> -->
          <div class="mensagemFAQ">
            {{ item.mensagem }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      helpers: [
        {
          indice: 1,
          titulo: "O que é o App da Turma?",
          mensagem:
            "É uma plataforma de cobrança e pagamento para grupos como comissões de formaturas, grupos de família, condomínios, times de esportes, clubes (motociclistas, Lions, Rotary), igrejas, entre outros. Qualquer grupo que deseja organizar seus recebimentos e pagamentos em um só lugar pode utilizar o App da Turma."
        },
        {
          indice: 2,
          titulo: "O dinheiro da turma é assegurado?",
          mensagem:
            "Sim, temos um seguro que garante 100% do dinheiro na plataforma, com custos muito acessíveis."
        },
        {
          indice: 3,
          titulo: "Como cadastrar minha turma ou evento?",
          mensagem:
            "É muito simples. Após um contato com nossa equipe para uma rápida apresentação e alinhamentos, a conta é aberta em minutos e já podem começar a arrecadação."
        },
        {
          indice: 4,
          titulo: "O App da Turma é um banco digital?",
          mensagem: `Não somos um banco digital. Somos uma plataforma de pagamentos e recebimentos focada em serviços de cobranças e pagamentos para grupos.`
        },
        {
          indice: 5,
          titulo: "O App da Turma é seguro?",
          mensagem:
            "Sim, cada grupo possui sua própria conta. As saídas não são automáticas e, se o grupo optar, as saídas só são realizadas com a aprovação dos membros de uma comissão de autorização criada pela turma."
        },
        {
          indice: 6,
          titulo: "O App da Turma cobra mensalidade?",
          mensagem: "Não. A turma só paga quando faz movimentações."
        },
        {
          indice: 7,
          titulo: "Posso pagar fornecedores pelo App da Turma?",
          mensagem: `Sim, essa é uma das grandes vantagens do App da Turma. As turmas de formandos podem pagar diretamente os fornecedores, reduzindo intermediários e custos.`
        },
        {
          indice: 8,
          titulo: "Quantas turmas posso cadastrar?",
          mensagem: `Não há limite. Um usuário pode participar de várias turmas com um único cadastro.`
        },
        {
          indice: 9,
          titulo: "O App da Turma funciona em todo o Brasil?",
          mensagem: `Sim, estamos presentes em todo o território nacional.`
        },
        {
          indice: 10,
          titulo: "Quais são as vantagens de usar o App da Turma?",
          mensagem:
            "Facilidade na arrecadação, transparência financeira, pagamentos organizados, centralização da tesouraria, documentos anexados na plataforma, Programa Formatura Custo Zero, devolução de valores em caso de cancelamento."
        },
        {
          indice: 11,
          titulo: "Posso fazer depósitos em dinheiro?",
          mensagem: `Sim, utilize a função de "depósito avulso". Crie um depósito, gere um boleto e pague no banco ou lotérica.`
        },
        {
          indice: 12,
          titulo:
            "Todos os participantes têm acesso às informações financeiras?",
          mensagem:
            "Essa é uma função opcional ao administrador da turma, mas sim, é possível que todos os participantes tenham acesso completo às informações financeiras."
        },
        {
          indice: 13,
          titulo: "O App da Turma realiza os eventos?",
          mensagem:
            "Não, focamos na gestão financeira. Para a execução do evento, recomendamos contratar empresas e profissionais especializados nessa área."
        },
        {
          indice: 14,
          titulo: "Posso usar a calculadora para qualquer evento?",
          mensagem:
            "Sim, com a calculadora é possível estimar vários cenários de qualquer evento."
        },
        {
          indice: 15,
          titulo: "Posso sacar os valores em dinheiro?",
          mensagem:
            "Não, os valores são movimentados via transferência bancária ou pagamento de boletos."
        },
        {
          indice: 16,
          titulo: "O que fazer se um participante atrasar o pagamento?",
          mensagem:
            "Os valores podem ser ajustados e as parcelas readequadas. Oferecemos serviço de cobrança ativo."
        },
        {
          indice: 17,
          titulo: "O que acontece se meu evento for cancelado?",
          mensagem:
            "Devolvemos 100% do valor arrecadado de forma simples e sem burocracia."
        },
        {
          indice: 18,
          titulo: "O que é o Programa Formatura Custo Zero?",
          mensagem:
            "É uma “vaquinha” online, com sorteios de prêmios para quem contribui."
        }
      ]
    };
  },
  methods: {
    openCloseChevron(indexSelected) {
      console.log("é oqyue ?!?!!", this.helpers[indexSelected].show);
      this.$nextTick(() => {
        this.helpers[indexSelected].show = !this.helpers[indexSelected].show;
      });

      console.log("é oqyue ?!?!!", this.helpers[indexSelected].show);
    }
  },
  mounted() {
    this.helpers = this.helpers.map(item => {
      return { ...item, show: false };
    });
  },
  computed: {
    renderHelpers() {
      return this.helpers.filter(item => {
        let itemTitulo = item.titulo.toUpperCase();
        let itemDescricao = item.mensagem.toUpperCase();
        let search = this.search.toUpperCase();
        if (itemTitulo.match(search) || itemDescricao.match(search)) {
          return true;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// pa-2 white ma-4 rounded-lg lowShadow
.cardFAQ {
  padding: 10px;
  background-color: white;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid rgba(93, 105, 117, 0.3);
}
.headerFAQ {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.tituloFAQ {
  font-weight: 500;
  font-size: 18px;
  font-family: "Roboto", sans-serif !important;
  color: #2f3a4a;
  margin-left: 10px;
}
.mensagemFAQ {
  font-size: 15px;
  padding: 10px;
  padding-top: 5px;
  line-height: 1.2rem;
  font-family: "Roboto", sans-serif !important;
  color: #778192;
}
.searchInput {
  outline: none;
}
.sizeIndex {
  border-radius: 10px;
  width: 50px;
  height: 50px;
}
.contemImagem {
  text-align: right;
}
.imagem {
  width: 150px;
}
</style>
